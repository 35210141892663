<template lang="pug">
  v-tabs(color="primary" v-model="activeTab" background-color="transparent" height="40px").app-tabs
    v-tab(v-for="tab in tabs" :key="tab.name" @click="switchTab(tab.value)")
      div {{ tab.label }}
</template>

<script>
import ROUTE_NAMES from '@/app/admin/router/router-const'
import { DRIVING_SCHOOL_TABS } from '../core/drivingSchool-const'

export default {
  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: DRIVING_SCHOOL_TABS.LIST,
          label: 'List',
          value: ROUTE_NAMES.DRIVING_SCHOOLS
        },
        {
          name: DRIVING_SCHOOL_TABS.APPLICATIONS,
          label: 'Applications',
          value: ROUTE_NAMES.DRIVING_SCHOOLS_APPLICATIONS
        },
        {
          name: DRIVING_SCHOOL_TABS.TRANSACTIONS,
          label: 'Transactions',
          value: ROUTE_NAMES.DRIVING_SCHOOLS_TRANSACTIONS
        }
      ]
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.name === value) return
      await this.$router.replace({ name: value, query: { page: 1 } })
      this.$emit('change')
    },

    setTabByQuery() {
      let tabIndex = this.tabs.findIndex(tab => tab.value === this.$route.name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  components: {
    btn: () => import('@/components/global/BtnCustom.vue')
  },

  watch: {
    '$route.query': function () {
      this.setTabByQuery()
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
